// body {
// 	overflow: hidden;
// }



.creator {

	background-color: #333;
	height: 100%;

	#company_info {
		position: absolute;
	    background-color: rgba(255,255,255,0.3);
	    color: black;
	    padding: 10px;
	    opacity: 0;

	    &.geen {
	    	display: none;
	    }
	    &.links_boven {
	    	top: 0;
	    	left: 0;
	    }
	    &.links_onder {
	    	bottom: 0;
	    	left: 0;
	    }
	    &.rechts_boven {
	    	top: 0;
	    	right: 0;
	    }
	    &.rechts_onder {
	    	bottom: 0;
	    	right: 0;
	    }
	}

	#loader {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 9;
		top: 0;
		left: 0;
		background-color: white;

		.fa-spinner {
			position: fixed;
			z-index: 10;
		    top: calc(50% - 25px);
		    left: calc(50% - 25px);
		    font-size: 70px;			
		}
	}

	#changeToLandscape {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 9;
		top: 0;
		left: 0;
		background-color: white;

		.content {
			position: fixed;
			z-index: 10;
		    top: calc(50%);
		    left: calc(50%);
		    transform: translate(-50%,-50%);
		    font-size: 20px;
		    text-align: center;

		    i {
		    	padding-bottom: 30px;
			    font-size: 70px;

		    }			
		}
	}

	.preview {

		position: relative;
		// perspective: 500px;
		overflow: hidden;
		max-width: 75%;
		// margin: 0 auto;

		.background {
		    width: 100%;
		    height: 100%;
		    background-size: cover;
		    background-position: center;
		    background-repeat: no-repeat;
		}	

		.flags_container {
			position: absolute;
			white-space: nowrap;
			width: 100%;

			.flag_container {
				display: inline-block;
				position: relative;


				.flag {
					position: absolute;
				    width: 53%;
				    height: 45%;
				    background: white;
				    left: 30%;
				    top: 6.5%;
				    overflow: hidden;

					.flag_brand {
						// position: absolute;
						// width: 100%;
						// height: 60%;
						// left: 30%;
						width: 100%;
						height: 100%;
						position: relative;


						.flag_brand_content {
							position: absolute;
							top: 50%;
							left: 50%;
							width: 100%;
							text-align: center;

							* {
								// font-size: 1.9em;
								font-size: inherit;
								line-height: inherit;
								margin-bottom: 0;
							}
						}
					}
				}

				.pole {
					width: 100%;
					position: relative;

					img {
						width: 100%;
						// position: absolute;
						// top: 0;
						// left: 0;

					}

					.overlay {
						mix-blend-mode: multiply;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}

		}
	}

	.menu_buttons {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.control_buttons {

		position: fixed;
		top: 0;
		right: 0;
		z-index: 1;

	}
	.control_btn {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		overflow: hidden;
		// background-color: #4c4cd8;
		background-color: #51afff;
		color: white;
		box-shadow: 0 0 2px 1px #000;
		border: none;
		display: block;
		margin: 10px;
	    line-height: 40px;
	    text-align: center;
	    padding: 0;

		&.save {

		}

		i {
			font-size: 20px;
		}

		&.active {
			background-color: #51afff;
		}
	}

	.control_sections {
	    
	    section {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 90px;

		    // background-color: rgba(255,255,255,0.5);
		    // background: rgb(173,173,173);
			// background: linear-gradient(353deg, rgba(231,230,230,0.8) 0%, rgba(250,250,250,0.8) 100%);
			background-color: rgba(255,255,255,0.4);
		    color: black;
		    font-weight: 900;
		    text-transform: uppercase;
	    	transform: translateY(100%);
	    	opacity: 0;
	    	transition: all 0.2s ease;


	    	&.active {
	    		// opacity: 0.9;
	    		opacity: 1;
	    		transform: translateY(0%);
	    	}
	    }
	}

	.flag_content_tabs {
		position: relative;
	    margin-top: -50px;
	    margin-bottom: 10px;
	    display: flex;

		.flag_content_tab {
		    width: 50px;
		    height: 40px;
		    text-align: center;
		    background-color: var(--primary);
		    margin: 0 2px;
		    line-height: 40px;

		    &.active {
		    	background-color: var(--secondary);
		    }
		}
	}
}


body.landscape {
	.control_btn:not(.back_btn) {
		display: block; 
	}
}

body.portrait {
	.control_btn:not(.back_btn) {
		display: inline-block; 
	}
}