input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    background-color: white;
    display: block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 0.25rem;
}