input[type=range] {
  width: 100%;
  margin: 15.8px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  background: #3771a9;
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
  margin-top: -10.8px;
  width: 30px;
  height: 30px;
  background: #3771a9;
  border: 0px solid #000000;
  box-shadow: 0px 0px 1px 1px #000;
  border-radius: 50px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #3771a9;
}
input[type=range]::-moz-range-track {
  background: #3771a9;
  border: 0;
  border-radius: 25px;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
}
input[type=range]::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background: #3771a9;
  border: 0.1px solid #000000;
  border-radius: 50px;
  cursor: pointer;
}
input[type=range]::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 16.8px 0;
  color: transparent;
  width: 100%;
  height: 10px;
  cursor: pointer;
}
input[type=range]::-ms-fill-lower {
  background: #3771a9;
  border: 0;
  border-radius: 50px;
}
input[type=range]::-ms-fill-upper {
  background: #3771a9;
  border: 0;
  border-radius: 50px;
}
input[type=range]::-ms-thumb {
  width: 30px;
  height: 30px;
  background: #3771a9;
  border: 0.1px solid #000000;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type=range]:focus::-ms-fill-lower {
  background: #3771a9;
}
input[type=range]:focus::-ms-fill-upper {
  background: #3771a9;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align:auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type=range] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
